import React, { useState, useEffect } from "react";
import { getProductsByCount, getProductsByFilter } from "../functions/product";
import ProductCard from "../components/cards/ProductCard";
import { Menu, Slider, Button, Breadcrumb } from "antd";

const { SubMenu } = Menu;

const ProductParameters = () => {
  const [products, setProducts] = useState([]);
  const [loading, setLoading] = useState(false);
  const [searchFilters, setSearchFilters] = useState({
    load: [0, 16],
    freq: [0, 28],
    min: [-80, 0],
    max: [0, 500],
  });

  useEffect(() => {
    loadAllProducts();
  }, []);

  const fetchProducts = (arg) => {
    getProductsByFilter(arg).then((res) => {
      setProducts(res.data);
    });
  };

  // load default items
  const loadAllProducts = () => {
    getProductsByCount(52).then((p) => {
      setProducts(p.data);
      setLoading(false);
    });
  };

  //load items by natural frequency
  useEffect(() => {
    fetchProducts(searchFilters);
  }, [searchFilters]);

  const refreshPage = ()=>{
    window.location.reload();
 }

  return (
    <>
      <div className="container-fluid p-0 mt-5">
        <div className="row mx-0 angle-product mt-5 position-absolute"></div>
      </div>

        <div className="container">
        <div className="row">
          <div className="col">
            {loading ? (
              <h4 className="text-center p-3 mb-3">Loading...</h4>
            ) : (
              <h1 className="text-danger mb-3">Product Parameters</h1>
            )}
          </div>
        </div>
      </div>

        <div className="container">
        <div className="row d-flex">
          <div className="col-md-12 d-flex flex-row-reverse  bread-margin">
            <Breadcrumb className="mt-2">
              <Breadcrumb.Item className="text-dark" href="/">
                <span>Home</span>
              </Breadcrumb.Item>
              <Breadcrumb.Item className="text-danger">
                Products
              </Breadcrumb.Item>
              <Breadcrumb.Item className="text-danger">
                Product Parameters
              </Breadcrumb.Item>
            </Breadcrumb>
          </div>
        </div>
      </div>

      <div className="container p-0">
        <div className="row mt-5">
          <div className="col-md-3">
            <h4>Filter Parameters</h4>
            <hr />
            <Menu defaultOpenKeys={["1", "2", "3", "4"]} mode="inline">
              <SubMenu
                key="1"
                title={
                  <span>
                    <h6 className="pt-4 text-center text-danger">
                      Load Bearing
                      <p>
                        <small>
                          MN/m<sup>2</sup>
                        </small>
                      </p>
                    </h6>
                  </span>
                }
              >
                <div>
                  <Slider
                    className="ml-4 mr-4"
                    tipFormatter={(v) => v}
                    range
                    value={searchFilters.load}
                    onChange={(value) =>
                      setSearchFilters({ ...searchFilters, load: value })
                    }
                    // defaultValue={16}
                    max={16}
                    min={0}
                  />
                </div>
                <label className="pl-3">0</label>
                <label className="float-right pr-3">16</label>
              </SubMenu>
              <SubMenu
                className="mt-4"
                key="2"
                title={
                  <span>
                    
                    <h6 className="pt-4 pb-1 text-center text-danger">
                      Natural Frequency
                      <p>
                        <small>Optimum - Hz</small>
                      </p>
                    </h6>
                  </span>
                }
              >
                <div>
                  <Slider
                    className="ml-4 mr-4"
                    tipFormatter={(v) => v}
                    range
                    // value={[5, 28]}
                    value={searchFilters.freq}
                    onChange={(value) =>
                      setSearchFilters({ ...searchFilters, freq: value })
                    }
                    max={28}
                    min={7}
                  />
                </div>
                <label className="pl-3">7</label>
                <label className="float-right pr-3">28</label>
              </SubMenu>
              <SubMenu
                className="mt-4"
                key="3"
                title={
                  <span>
                    <h6 className="pt-4 text-center text-danger">
                      Min Temperature
                      <p>
                        <small>
                          <sup>o</sup>C
                        </small>
                      </p>
                    </h6>
                  </span>
                }
              >
                <div>
                  <Slider
                    className="ml-4 mr-4"
                    tipFormatter={(v) => v}
                    range
                    value={searchFilters.min}
                    onChange={(value) =>
                      setSearchFilters({ ...searchFilters, min: value })
                    }
                    max={0}
                    min={-80}
                  />
                </div>
                <label className="pl-2">-80</label>
                <label className="float-right pr-3">0</label>
              </SubMenu>
              <SubMenu
                className="mt-4"
                key="4"
                title={
                  <span>
                    <h6 className="pt-4 text-center text-danger">
                      Max Temperature
                      <p>
                        <small>
                          <sup>o</sup>C
                        </small>
                      </p>
                    </h6>
                  </span>
                }
              >
                <div>
                  <Slider
                    className="ml-4 mr-4"
                    tipFormatter={(v) => v}
                    range
                    value={searchFilters.max}
                    onChange={(value) =>
                      setSearchFilters({ ...searchFilters, max: value })
                    }
                    max={500}
                  />
                </div>
                <label className="pl-3">0</label>
                <label className="float-right pr-2">500</label>
              </SubMenu>
            </Menu>
            <Button
            onClick={refreshPage}
              type="primary"
              size="large"
              className="reset float-left ml-3 mt-3 mb-5"
            >
              Reset
            </Button>
          </div>
          <div className="col-md-9">
            {loading ? (
              <h4 className="text-danger">Loading...</h4>
            ) : (
              <h4 className="text-danger">Filter Results</h4>
            )}
            {products.length < 1 && <p>No items found</p>}
            <div className="row pb-5">
              {products.map((p) => (
                <div key={p._id} className="col-sm-6 col-md-4 mt-3">
                  <ProductCard product={p} />
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default ProductParameters;