import React, { useEffect, useState } from "react";
import AdminNav from "../../../components/nav/AdminNav";
import { getProductsByCount } from "../../../functions/product";
import AdminProductCard from "../../../components/cards/AdminProductCard";
import { removeProduct } from "../../../functions/product";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";

const AllProducts = () => {
  const [products, setProducts] = useState([]);
  const [loading, setLoading] = useState(false);
  const { user } = useSelector((state) => ({ ...state }));

  useEffect(() => {
    loadAllProducts();
  }, []);

  const loadAllProducts = () => {
    setLoading(true);
    getProductsByCount(50)
    .then((res) => {
      setProducts(res.data);
      setLoading(false);
    })
    .catch((err) => {
      setLoading(false);
       console.log(err);
  });
  };

  const handleRemove = (slug) => {
    // let answer = window.confirm("Are you sure you want to delete?");
    if (window.confirm("Are you sure you want to delete?")) {
      // console.log("Send delete request", slug);
      removeProduct(slug, user.token)
      .then(res => {
        loadAllProducts();
        toast.error(`${res.data.title} has been deleted`);
      })
      .catch(err => {
        if (err.response.status === 400) toast.error(err.response.data);
        console.log(err);
      });
    }
  };

    return (
      <div className="container-fluid p-0 mt-5">
      <div className="row mx-0 angle-product mt-5 position-absolute">
      </div>
      <div className="container">
      <div className="row">
            <div className="col">
              <h1 className="text-danger mb-3">Admin</h1>
            </div>
          </div>
<div className="row mt-5">
  <div className="col-md-2">
    <AdminNav />
  </div>
      <div className="col">
      {loading ? (
      <h4 className="text-danger">Loading...</h4>
      ) : (
      <h4>All Products</h4>
      )}
      <div className="row">
      {products.map((product) => (
        <div key={product._id} className="col-md-4 pb-3">
        <AdminProductCard product={product} handleRemove={handleRemove} />
        </div>
      ))}
      </div>
        </div>
    </div>
  </div>
  </div>
    );
};

export default AllProducts;