import React, { useState, useEffect } from "react";
import AdminNav from "../../../components/nav/AdminNav";
import { toast } from "react-toastify";
import { useSelector } from "react-redux";
import { createSolution, getSolutions, removeSolution} from "../../../functions/solution";
import { Link } from "react-router-dom";
import { EditOutlined, DeleteOutlined } from '@ant-design/icons';
import CategoryForm from "../../../components/forms/CategoryForm";
import LocalSearch from "../../../components/forms/LocalSearch";

const SolutionCreate = () => {
  const { user } = useSelector((state) => ({ ...state }));
  const [name, setName] = useState("");
  const [loading, setLoading] = useState(false);
  const [solutions, setSolutions] = useState([]);

  // step 1
  const [keyword, setKeyword] = useState("");

  useEffect(() => {
    loadSolutions();
  }, []);

    const loadSolutions = () =>
    getSolutions().then((u) => setSolutions(u.data));

  const handleSubmit = (e) => {
    e.preventDefault();
    // console.log(name);
    setLoading(true);
    createSolution({ name }, user.token)
      .then((res) => {
        // console.log(res)
        setLoading(false);
        setName("");
        toast.success(`"${res.data.name}" is created`);
        loadSolutions();
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
        if (err.response.status === 400) toast.error(err.response.data);
      });
  };

  const handleRemove = async(slug) => {
    if(window.confirm("Delete?")) {
      setLoading(true)
      removeSolution(slug, user.token)
      .then(res => {
        setLoading(false);
        toast.error(`${res.data.name} deleted`);
        loadSolutions();
      })
      .catch(err => {
        if (err.response.status === 400) {
          setLoading(false);
          toast.error(err.response.data);
        }
      });
    }
  };

  // step 3
  // const handleSearchChange = (e) => {
  //   e.preventDefault();
  //   setKeyword(e.target.value.toLowerCase());
  // };

// step 4
  const searched = (keyword) => (u) => u.name.toLowerCase().includes(keyword);


    return (
      <div className="container-fluid p-0 mt-5">
      <div className="row mx-0 angle-product mt-5 position-absolute">
      </div>
      <div className="container">
      <div className="row">
            <div className="col">
              <h1 className="text-danger mb-3">Admin</h1>
            </div>
          </div>
<div className="row mt-5">
  <div className="col-md-2">
    <AdminNav />
  </div>
      <div className="col">
      {loading ? (
      <h4 className="text-danger">Loading...</h4>
      ) : (
        <h4>Create Solution</h4>
      )}
      <CategoryForm
      handleSubmit={handleSubmit}
      name={name}
      setName={setName}
      />

      {/* // step 2 */}
      

      <LocalSearch keyword={keyword} setKeyword={setKeyword} />

      {/* step 5 */}
      {solutions.filter(searched(keyword)).map((u) =>(
        <div className="alert alert-secondary" key={u._id}>
        {u.name}
        <span  onClick={() => handleRemove(u.slug)} className="btn btn-sm float-right">
        <DeleteOutlined className="text-danger" />
        </span>
        <Link to={`/admin/solution/${u.slug}`}>
        <span className="btn btn-sm float-right">
        <EditOutlined className="text-warning" />
        </span>
        </Link>
        </div>
      ))}
      </div>
    </div>
  </div>
  </div>
    );
};

export default SolutionCreate;