import React from "react";
import { Card } from "antd";
// import { Link } from "react-router-dom";
import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import defaul from "../../images/default-tico.png";
import FeatureListItems from "./FeatureListItems";
import DesignListItems from "./DesignListItems";

const { Meta } = Card;

const SingleProduct = ({ product }) => {
  const { title, images, description } = product;

  return (
    <>
      <div className="container">
        <div className="row">
          <div className="col-md-6 pr-5 pl-5 pb-5">
            {images && images.length ? (
              <Carousel
                showArrows={true}
                showThumbs={false}
                autoPlay
                infiniteLoop
                showStatus={false}
              >
                {images &&
                  images.map((i) => (
                    <img
                      src={i.url}
                      key={i.public_id}
                      alt="Carousel of TICO Items"
                    />
                  ))}
              </Carousel>
            ) : (
              <Card
                cover={
                  <img
                    src={defaul}
                    className="mb-3 card-image"
                    alt="TICO default if uploaded is missing"
                  />
                }
              ></Card>
            )}
          </div>

          <div className="col-md-6 pl-0 pb-5">
            <h1 className="mt-5 pt-1 text-danger">{title}</h1>
            <Card bordered={false} bodyStyle={{ padding: "0" }}>
              <Meta description={description} />
              {/* <FeatureListItems product={product} /> */}
            </Card>
          </div>
        </div>
      </div>

      <div className="container-fluid bg p-0">
        <div className="row">
          <div className="col-md-12 bgstill">
            <div className="container">
              <div className="row pt-5">
                <div className="col-12">
                  <h3 className="text-danger text-center">Key Features</h3>
                </div>
              </div>
              <div className="row pb-5 justify-content-center">
                <div className="col-md-5">
                  <FeatureListItems product={product} />
                </div>
                <div className="col-md-5">
                  <DesignListItems product={product} />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default SingleProduct;
