import {
  Card,
  Button,
  Breadcrumb,
} from "antd";
import React from "react";
import { Link } from "react-router-dom";

const imgStyle: React.CSSProperties = {
  width: "100%",
  height: "auto",
  lineHeight: "160px",
  textAlign: "center",
};

const { Meta } = Card;

const Oem = () => {
  return (
    <>
      <div className="container-fluid main p-0">
        <div className="row mx-0">
          <div className="col p-0 main stackParent">
            <img
              className="img-fluid head-img"
              width="100%"
              height="500px"
              src="https://res.cloudinary.com/dkfjd6cdn/image/upload/v1669117120/tico-solutions-tabs/industrial-oem-header_lq5ho5.jpg"
              alt="TICO and the many industries we supply to for noise and vibration isolations"
              preview={false}
            />
            <div className="stack-Txt">
              <div className="stack-Txt-child">OEM</div>
            </div>
          </div>
        </div>
      </div>

      <div className="container-fluid p-0 mt-n5">
        <div className="row">
          <div className="white-angle"></div>
        </div>
      </div>

      <div className="container mt-n5 p-0">
        <div className="row d-flex">
          <div className="col-md-12 d-flex flex-row-reverse">
            <Breadcrumb>
              <Breadcrumb.Item className="text-dark" href="/">
                <span>Home</span>
              </Breadcrumb.Item>
              <Breadcrumb.Item className="text-dark" href="/industries">
                <span>Industries</span>
              </Breadcrumb.Item>
              <Breadcrumb.Item className="text-danger">OEM</Breadcrumb.Item>
            </Breadcrumb>
          </div>
        </div>
      </div>

      <div className="container p-0">
        <div className="row">
          <div className="col-12">
            <h1 className="mt-5 text-danger">
              Products suitable for OEM applications
            </h1>
            <p className="lead p-0 mt-2 mb-5">
              As the manufacturer of the TICO Anti-vibration range of products
              we are able to assist OEM's with bespoke and unique anti-vibration
              requirements. Our extensive manufacturing capabilities means we
              can undertake almost any specific requirement for TICO pads
              including rubber to metal bonding, CNC routering, water jet
              cutting and producing pads to specific load, sheer and
              compressibility requirements.
            </p>
          </div>
        </div>
      </div>

      <div className="container">
        <div className="row justify-content-md-center mb-5 pb-5">
          <div className="col-12 mt-5">
            <h3 className="text-center">Typical Applications</h3>
          </div>
          <div className="col-6 col-md-3 pb-3">
            <div>
              <img
                style={imgStyle}
                src="https://res.cloudinary.com/dkfjd6cdn/image/upload/v1659618653/tico-solutions-tabs/industrial-for-construction_vuhafe.png"
                alt="We supply many product types to the power industry for isolating transformers"
              />
            </div>
          </div>
          <div className="col-6 col-md-3 pb-3">
            <div>
              <img
                style={imgStyle}
                src="https://res.cloudinary.com/dkfjd6cdn/image/upload/v1659618655/tico-solutions-tabs/construction-for-construction_qvddoc.png"
                alt="We supply many product types to the power industry for isolating switch gear"
              />
            </div>
          </div>
          <div className="col-6 col-md-3 pb-3">
            <div>
              <img
                style={imgStyle}
                src="https://res.cloudinary.com/dkfjd6cdn/image/upload/v1659618653/tico-solutions-tabs/production-for-construction_bylwgx.png"
                alt="We supply many product types to the power industry for isolating generators"
              />
            </div>
          </div>
          <div className="w-100 d-none d-md-block"></div>
          <div className="col-6 col-md-3 pb-3">
            <div>
              <img
                style={imgStyle}
                src="https://res.cloudinary.com/dkfjd6cdn/image/upload/v1659618653/tico-solutions-tabs/fabrication-for-construction_iszee9.png"
                alt="We supply many product types to the power industry for isolating wind turbines"
              />
            </div>
          </div>
          <div className="col-6 col-md-3 pb-3">
            <div>
              <img
                style={imgStyle}
                src="https://res.cloudinary.com/dkfjd6cdn/image/upload/v1659618655/tico-solutions-tabs/metrology-for-construction_odq9aj.png"
                alt="We supply many product types to the power industry for isolating compressors"
              />
            </div>
          </div>
          <div className="col-6 col-md-3 pb-3">
            <div>
              <img
                style={imgStyle}
                src="https://res.cloudinary.com/dkfjd6cdn/image/upload/v1659618655/tico-solutions-tabs/forging-for-construction_al3gsg.png"
                alt="We supply many product types to the power industry for isolating offshore turbines etc."
              />
            </div>
          </div>
        </div>
      </div>

      <div className="row mt-5 mb-5 bgstill">
        <div className="container">
          <div className="col-12 pt-5 pb-4">
            <p className="lead text-center p-5 mt-5 mb-5">
              We operate an extensive Research &amp; Development team who along
              with our TICO Engineers are able to work with you to provide the
              most cost efficient and effective solution.
            </p>
          </div>
        </div>
      </div>

      <div className="container">
        {/* row 1 of products */}
        <div className="row pt-5">
          <div className="col-12 mt-5">
            <h3>Suitable Products</h3>
            {/* <p className="lead">We have developed a range of anti-vibration, high temperature and fire retardant Pipe Support products for use within the Oil &amp; Gas industry including Pipe Supports, Clamp Lining Clip Strips and Sliding Bearings.
</p> */}
          </div>

          <div className="col-md-4 mb-4">
            <Card
              cover={
                <img
                  alt="Anti-vibration material for general machine mounting applications"
                  src="https://res.cloudinary.com/dkfjd6cdn/image/upload/v1663852778/arssgyddqarkbccmmxyh.jpg"
                />
              }
              actions={[
                <Link to={`/sol/general-machine-mounting`}>
                  <Button type="primary" className="float-left ml-4">
                    View Product
                  </Button>
                </Link>,
              ]}
            >
              <Link to={`/sol/general-machine-mounting`}>
                <Meta
                  title="General Machine Mounting"
                  description="TICO's general purpose pads are high performance machinery mounting materials, which are manufactured from a..."
                />
              </Link>
            </Card>
          </div>

          <div className="col-md-4 mb-4">
            <Card
              cover={
                <img
                  alt="Vibration isolation material suitable for low frequency applications"
                  src="https://res.cloudinary.com/dkfjd6cdn/image/upload/v1663852782/izlnvdbn6kfy18zxleu1.jpg"
                />
              }
              actions={[
                <Link to={`/sol/low-frequency-machine-mounting`}>
                  <Button type="primary" className="float-left ml-4">
                    View Product
                  </Button>
                </Link>,
              ]}
            >
              <Link to={`/sol/low-frequency-machine-mounting`}>
                <Meta
                  title="Low Frequency Machine Mounting"
                  description="There are certain applications where a very high degree of isolation is required &#45; in particular, applications..."
                />
              </Link>
            </Card>
          </div>

          <div className="col-md-4 mb-4">
            <Card
              cover={
                <img
                  alt="Tico material for heavy or high duty impact load applications"
                  src="https://res.cloudinary.com/dkfjd6cdn/image/upload/v1663853055/w0skalomdzared0nkheo.jpg"
                />
              }
              actions={[
                <Link to={`/sol/heavy-duty-machine-mounting`}>
                  <Button type="primary" className="float-left ml-4">
                    View Product
                  </Button>
                </Link>,
              ]}
            >
              <Link to={`/sol/heavy-duty-machine-mounting`}>
                <Meta
                  title="Heavy/high duty Machine Mounting"
                  description="Designed to reduce shock, impact loads and vibration in a wide range of applications in heavy industries..."
                />
              </Link>
            </Card>
          </div>
        </div>

        {/* row 2 of products */}

        <div className="row">
          <div className="col-md-4 mb-4">
            <Card
              cover={
                <img
                  alt="Tico sandwich mount"
                  src="https://res.cloudinary.com/dkfjd6cdn/image/upload/v1664283803/sbry0lsgdwzhrgjkgzpv.jpg"
                />
              }
              actions={[
                <Link to={`/sol/sandwich-mounts`}>
                  <Button type="primary" className="float-left ml-4">
                    View Product
                  </Button>
                </Link>,
              ]}
            >
              <Link to={`/sol/sandwich-mounts`}>
                <Meta
                  title="Sandwich Mount"
                  description="LBM3 Sandwich Mounts comprises of two high quality rubber bonded cork elastomeric pads, bonded between two outer..."
                />
              </Link>
            </Card>
          </div>

          <div className="col-md-4 mb-4">
            <Card
              cover={
                <img
                  alt="bolt isolation between insert and machine base"
                  src="https://res.cloudinary.com/dkfjd6cdn/image/upload/v1669220979/tacidxfz5e0bpjnr8y7g.jpg"
                />
              }
              actions={[
                <Link to={`/sol/bolt-isolation`}>
                  <Button type="primary" className="float-left ml-4">
                    View Product
                  </Button>
                </Link>,
              ]}
            >
              <Link to={`/sol/bolt-isolation`}>
                <Meta
                  title="Bolt Isolation"
                  description="Bolt Isolation prevents transmission of machine noise and vibration through the bolt to the surrounding..."
                />
              </Link>
            </Card>
          </div>

          <div className="col-md-4 mb-4">
            <Card
              cover={
                <img
                  alt="2 part epoxy adhesive"
                  src="https://res.cloudinary.com/dkfjd6cdn/image/upload/v1663851425/rrvrs3syutydvfa1mz0g.jpg"
                />
              }
              actions={[
                <Link to={`/sol/2-part-epoxy-adhesive`}>
                  <Button type="primary" className="float-left ml-4">
                    View Product
                  </Button>
                </Link>,
              ]}
            >
              <Link to={`/sol/2-part-epoxy-adhesive`}>
                <Meta
                  title="2 Part Epoxy Adhesive"
                  description="Two part epoxy adhesive is designed to provide excellent bonding properties, particularly in applications..."
                />
              </Link>
            </Card>
          </div>
        </div>

        {/* row 3 of products */}

        <div className="row">
          <div className="col-md-4 mb-4">
            <Card
              cover={
                <img
                  alt="2 part epoxy adhesive"
                  src="https://res.cloudinary.com/dkfjd6cdn/image/upload/v1663851425/rrvrs3syutydvfa1mz0g.jpg"
                />
              }
              actions={[
                <Link to={`/sol/tico-contact-s-adhesive`}>
                  <Button type="primary" className="float-left ml-4">
                    View Product
                  </Button>
                </Link>,
              ]}
            >
              <Link to={`/sol/tico-contact-s-adhesive`}>
                <Meta
                  title="TICO Contact Adhesive"
                  description="Exhibits excellent adhesion to most surfaces and has good heat resistance. Bonds may be made over a wide range..."
                />
              </Link>
            </Card>
          </div>

          <div className="col-md-4 mb-4">
            <Card
              cover={
                <img
                  alt="Tico pit installation anti-vibration material"
                  src="https://res.cloudinary.com/dkfjd6cdn/image/upload/v1664785151/jcifvggszceqhck96ehs.jpg"
                />
              }
              actions={[
                <Link to={`/sol/pit-installation`}>
                  <Button type="primary" className="float-left ml-4">
                    View Product
                  </Button>
                </Link>,
              ]}
            >
              <Link to={`/sol/pit-installation`}>
                <Meta
                  title="Pit installation"
                  description="Anti-vibration material for use in Foundation Inertia Block (pit and plinth) installations across a wide variety of industries...."
                />
              </Link>
            </Card>
          </div>

          <div className="col-md-4 mb-4">
            <Card
              cover={
                <img
                  alt="Tico plinth installation solutions"
                  src="https://res.cloudinary.com/dkfjd6cdn/image/upload/v1663852225/br86suho0oykpdgunogl.jpg"
                />
              }
              actions={[
                <Link to={`/sol/plinth-installation`}>
                  <Button type="primary" className="float-left ml-4">
                    View Product
                  </Button>
                </Link>,
              ]}
            >
              <Link to={`/sol/plinth-installation`}>
                <Meta
                  title="Plinth Installation"
                  description="The main reasons for using a foundation block are to provide additional mass to improve the rigidity of the machine..."
                />
              </Link>
            </Card>
          </div>
        </div>

        {/* row 4 of products */}

        <div className="row">
          <div className="col-md-4 mb-4">
            <Card
              cover={
                <img
                  alt="Tico levelling mounts"
                  src="https://res.cloudinary.com/dkfjd6cdn/image/upload/v1665504300/itksbvk38kahiv3igvvh.jpg"
                />
              }
              actions={[
                <Link to={`/sol/tico-adjustamounts`}>
                  <Button type="primary" className="float-left ml-4">
                    View Product
                  </Button>
                </Link>,
              ]}
            >
              <Link to={`/sol/tico-adjustamounts`}>
                <Meta
                  title="Levelling Mounts"
                  description="TICO Adjustamounts (levelling mounts) are specially designed for mounting machines and structures where..."
                />
              </Link>
            </Card>
          </div>
        </div>
      </div>
    </>
  );
};

export default Oem;
