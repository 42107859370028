import {
  Card,
  Button,
  Breadcrumb,
} from "antd";
import React from "react";
import { Link } from "react-router-dom";

const imgStyle: React.CSSProperties = {
  width: "100%",
  height: "auto",
  lineHeight: "160px",
  textAlign: "center",
};

const { Meta } = Card;

const LiftEscalator = () => {
  return (
    <>
      <div className="container-fluid main p-0">
        <div className="row mx-0">
          <div className="col p-0 main stackParent">
            <img
              className="img-fluid head-img"
              width="100%"
              height="500px"
              src="https://res.cloudinary.com/dkfjd6cdn/image/upload/v1669117120/tico-solutions-tabs/industrial-lift-escalator-header_newnhz.jpg"
              alt="TICO and the many industries we supply to for noise and vibration isolations"
              preview={false}
            />
            <div className="stack-Txt">
              <div className="stack-Txt-child">Lifts &amp; Escalators</div>
            </div>
          </div>
        </div>
      </div>

      <div className="container-fluid p-0 mt-n5">
        <div className="row">
          <div className="white-angle"></div>
        </div>
      </div>

      <div className="container mt-n5 p-0">
        <div className="row d-flex">
          <div className="col-md-12 d-flex flex-row-reverse">
            <Breadcrumb>
              <Breadcrumb.Item className="text-dark" href="/">
                <span>Home</span>
              </Breadcrumb.Item>
              <Breadcrumb.Item className="text-dark" href="/industries">
                <span>Industries</span>
              </Breadcrumb.Item>
              <Breadcrumb.Item className="text-danger">
                Lifts &amp; Escalators
              </Breadcrumb.Item>
            </Breadcrumb>
          </div>
        </div>
      </div>

      <div className="container p-0">
        <div className="row">
          <div className="col-12">
            <h1 className="mt-5 text-danger">
              Products suitable for Lifts &amp; Escalators
            </h1>
            <p className="lead p-0 mt-2 mb-5">
              TICO has developed a range of products for use within the Lift and
              Escalator industry. Our products are all designed to attenuate the
              transference of noise and vibration from the gearing and lift
              mechanism into adjacent rooms and buildings.
            </p>
          </div>
        </div>
      </div>

      <div className="container">
        <div className="row justify-content-md-center mb-5 pb-5">
          <div className="col-12 mt-5">
            <h3 className="text-center">Typical Applications</h3>
          </div>
          <div className="col-6 col-md-3 pb-3">
            <div>
              <img
                style={imgStyle}
                src="https://res.cloudinary.com/dkfjd6cdn/image/upload/v1659620029/tico-solutions-tabs/lift-for-lifts-escalators_gwtpfm.png"
                alt="Tico products suitable for the lifts and escalators - lifts or elevators"
              />
            </div>
          </div>
          <div className="col-6 col-md-3 pb-3">
            <div>
              <img
                style={imgStyle}
                src="https://res.cloudinary.com/dkfjd6cdn/image/upload/v1659620029/tico-solutions-tabs/escalators-for-lifts-escalators_gxr0dp.png"
                alt="Tico products suitable for the lifts and escalators - escalators"
              />
            </div>
          </div>
          <div className="col-6 col-md-3 pb-3">
            <div>
              <img
                style={imgStyle}
                src="https://res.cloudinary.com/dkfjd6cdn/image/upload/v1659620029/tico-solutions-tabs/maintenance-for-lifts-escalators_n9grtt.png"
                alt="Tico products suitable for the lifts and escalators - maintenance"
              />
            </div>
          </div>
        </div>
      </div>

      <div className="row mt-5 mb-5 bgstill">
        <div className="container">
          <div className="col-12 mt-5 pt-3 pb-4">
            <p className="lead text-center p-5 mt-5 mb-5">
              TICO is specified by many of the leading names in Lift
              Manufacturing and our product range which incorporates TICO
              Collars and washers as well as impact and vibration absorption
              pads, both off-the-shelf and bespoke manufactured are used
              throughout the lift installation process.
            </p>
          </div>
        </div>
      </div>

      <div className="container">
        {/* row 1 of products */}
        <div className="row">
          <div className="col-12 mt-5">
            <h3>Suitable Products</h3>
            <p className="lead">
              Our Technical Teams are available to discuss and provide further
              support on choosing the correct TICO product for every vibration
              problem.
            </p>
          </div>

          <div className="col-md-4 mb-4">
            <Card
              cover={
                <img
                  alt="Tico pit installation anti-vibration material"
                  src="https://res.cloudinary.com/dkfjd6cdn/image/upload/v1664785151/jcifvggszceqhck96ehs.jpg"
                />
              }
              actions={[
                <Link to={`/sol/pit-installation`}>
                  <Button type="primary" className="float-left ml-4">
                    View Product
                  </Button>
                </Link>,
              ]}
            >
              <Link to={`/sol/pit-installation`}>
                <Meta
                  title="Pit installation"
                  description="Anti-vibration material for use in Foundation Inertia Block (pit and plinth) installations across a wide variety of industries...."
                />
              </Link>
            </Card>
          </div>

          <div className="col-md-4 mb-4">
            <Card
              cover={
                <img
                  alt="Tico plinth installation solutions"
                  src="https://res.cloudinary.com/dkfjd6cdn/image/upload/v1663852225/br86suho0oykpdgunogl.jpg"
                />
              }
              actions={[
                <Link to={`/sol/plinth-installation`}>
                  <Button type="primary" className="float-left ml-4">
                    View Product
                  </Button>
                </Link>,
              ]}
            >
              <Link to={`/sol/plinth-installation`}>
                <Meta
                  title="Plinth Installation"
                  description="The main reasons for using a foundation block are to provide additional mass to improve the rigidity of the machine..."
                />
              </Link>
            </Card>
          </div>

          <div className="col-md-4 mb-4">
            <Card
              cover={
                <img
                  alt="Anti-vibration material for general machine mounting applications"
                  src="https://res.cloudinary.com/dkfjd6cdn/image/upload/v1663852778/arssgyddqarkbccmmxyh.jpg"
                />
              }
              actions={[
                <Link to={`/sol/general-machine-mounting`}>
                  <Button type="primary" className="float-left ml-4">
                    View Product
                  </Button>
                </Link>,
              ]}
            >
              <Link to={`/sol/general-machine-mounting`}>
                <Meta
                  title="General Machine Mounting"
                  description="TICO's general purpose pads are high performance machinery mounting materials, which are manufactured from a..."
                />
              </Link>
            </Card>
          </div>
        </div>

        {/* row 2 of products */}

        <div className="row">
          <div className="col-md-4 mb-4">
            <Card
              cover={
                <img
                  alt="Vibration isolation material suitable for low frequency applications"
                  src="https://res.cloudinary.com/dkfjd6cdn/image/upload/v1663852782/izlnvdbn6kfy18zxleu1.jpg"
                />
              }
              actions={[
                <Link to={`/sol/low-frequency-machine-mounting`}>
                  <Button type="primary" className="float-left ml-4">
                    View Product
                  </Button>
                </Link>,
              ]}
            >
              <Link to={`/sol/low-frequency-machine-mounting`}>
                <Meta
                  title="Low Frequency Machine Mounting"
                  description="There are certain applications where a very high degree of isolation is required &#45; in particular, applications..."
                />
              </Link>
            </Card>
          </div>

          <div className="col-md-4 mb-4">
            <Card
              cover={
                <img
                  alt="Tico material for heavy or high duty impact load applications"
                  src="https://res.cloudinary.com/dkfjd6cdn/image/upload/v1663853055/w0skalomdzared0nkheo.jpg"
                />
              }
              actions={[
                <Link to={`/sol/heavy-duty-machine-mounting`}>
                  <Button type="primary" className="float-left ml-4">
                    View Product
                  </Button>
                </Link>,
              ]}
            >
              <Link to={`/sol/heavy-duty-machine-mounting`}>
                <Meta
                  title="Heavy/high duty Machine Mounting"
                  description="Designed to reduce shock, impact loads and vibration in a wide range of applications in heavy industries..."
                />
              </Link>
            </Card>
          </div>

          <div className="col-md-4 mb-4">
            <Card
              cover={
                <img
                  alt="bolt isolation between insert and machine base"
                  src="https://res.cloudinary.com/dkfjd6cdn/image/upload/v1669220979/tacidxfz5e0bpjnr8y7g.jpg"
                />
              }
              actions={[
                <Link to={`/sol/bolt-isolation`}>
                  <Button type="primary" className="float-left ml-4">
                    View Product
                  </Button>
                </Link>,
              ]}
            >
              <Link to={`/sol/bolt-isolation`}>
                <Meta
                  title="Bolt Isolation"
                  description="Bolt Isolation prevents transmission of machine noise and vibration through the bolt to the surrounding..."
                />
              </Link>
            </Card>
          </div>
        </div>

        {/* row 3 of products */}

        <div className="row">
          <div className="col-md-4 mb-4">
            <Card
              cover={
                <img
                  alt="2 part epoxy adhesive"
                  src="https://res.cloudinary.com/dkfjd6cdn/image/upload/v1663851425/rrvrs3syutydvfa1mz0g.jpg"
                />
              }
              actions={[
                <Link to={`/sol/2-part-epoxy-adhesive`}>
                  <Button type="primary" className="float-left ml-4">
                    View Product
                  </Button>
                </Link>,
              ]}
            >
              <Link to={`/sol/2-part-epoxy-adhesive`}>
                <Meta
                  title="2 Part Epoxy Adhesive"
                  description="Two part epoxy adhesive is designed to provide excellent bonding properties, particularly in applications..."
                />
              </Link>
            </Card>
          </div>

          <div className="col-md-4 mb-4">
            <Card
              cover={
                <img
                  alt="Tico sandwich mount"
                  src="https://res.cloudinary.com/dkfjd6cdn/image/upload/v1664283803/sbry0lsgdwzhrgjkgzpv.jpg"
                />
              }
              actions={[
                <Link to={`/sol/sandwich-mounts`}>
                  <Button type="primary" className="float-left ml-4">
                    View Product
                  </Button>
                </Link>,
              ]}
            >
              <Link to={`/sol/sandwich-mounts`}>
                <Meta
                  title="Sandwich Mount"
                  description="LBM3 Sandwich Mounts comprises of two high quality rubber bonded cork elastomeric pads, bonded between two outer..."
                />
              </Link>
            </Card>
          </div>

          <div className="col-md-4 mb-4">
            <Card
              cover={
                <img
                  alt="2 part epoxy adhesive"
                  src="https://res.cloudinary.com/dkfjd6cdn/image/upload/v1663851425/rrvrs3syutydvfa1mz0g.jpg"
                />
              }
              actions={[
                <Link to={`/sol/tico-contact-s-adhesive`}>
                  <Button type="primary" className="float-left ml-4">
                    View Product
                  </Button>
                </Link>,
              ]}
            >
              <Link to={`/sol/tico-contact-s-adhesive`}>
                <Meta
                  title="TICO Contact Adhesive"
                  description="Exhibits excellent adhesion to most surfaces and has good heat resistance. Bonds may be made over a wide range..."
                />
              </Link>
            </Card>
          </div>
        </div>
      </div>
    </>
  );
};

export default LiftEscalator;
