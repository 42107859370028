import React, { useState, useEffect } from "react";
import AdminNav from "../../../components/nav/AdminNav";
import { toast } from "react-toastify";
import { useSelector } from "react-redux";
import { getSolutions } from "../../../functions/solution";
import { createSubsol, removeSubsol, getSubsols } from "../../../functions/subsol";
import { Link } from "react-router-dom";
import { EditOutlined, DeleteOutlined } from '@ant-design/icons';
import CategoryForm from "../../../components/forms/CategoryForm";
import LocalSearch from "../../../components/forms/LocalSearch";

const SubsolCreate = () => {
  const { user } = useSelector((state) => ({ ...state }));
  const [name, setName] = useState("");
  const [loading, setLoading] = useState(false);
  const [solutions, setSolutions] = useState([]);
  const [solution, setSolution] = useState("");
  const [subsols, setSubsols] = useState([]);

  // step 1
  const [keyword, setKeyword] = useState("");

  useEffect(() => {
    loadSolutions();
    loadSubsols();
  }, []);

    const loadSolutions = () =>
    getSolutions().then((u) => setSolutions(u.data));

    const loadSubsols = () =>
    getSubsols().then((b) => setSubsols(b.data));

  const handleSubmit = (e) => {
    e.preventDefault();
    // console.log(name);
    setLoading(true);
    createSubsol({ name, parent: solution }, user.token)
      .then((res) => {
        // console.log(res)
        setLoading(false);
        setName("");
        toast.success(`"${res.data.name}" is created`);
       loadSubsols();
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
        if (err.response.status === 400) toast.error(err.response.data);
      });
  };

  const handleRemove = async(slug) => {
    if(window.confirm("Delete?")) {
      setLoading(true)
      removeSubsol(slug, user.token)
      .then(res => {
        setLoading(false);
        toast.error(`${res.data.name} deleted`);
        loadSubsols();
      })
      .catch(err => {
        if (err.response.status === 400) {
          setLoading(false);
          toast.error(err.response.data);
        }
      });
    }
  };

// step 4
  const searched = (keyword) => (u) => u.name.toLowerCase().includes(keyword);


    return (
      <div className="container-fluid p-0 mt-5">
      <div className="row mx-0 angle-product mt-5 position-absolute">
      </div>
      <div className="container">
      <div className="row">
            <div className="col">
              <h1 className="text-danger mb-3">Admin</h1>
            </div>
          </div>
<div className="row mt-5">
  <div className="col-md-2">
    <AdminNav />
  </div>
      <div className="col">
      {loading ? (
      <h4 className="text-danger">Loading...</h4>
      ) : (
        <h4>Create sub solution</h4>
      )}

      <div className="form-group">
      <label>Parent Solution</label>
      <select
      name="solution"
      className="form-control"
      onChange={e => setSolution(e.target.value)}
      >
      <option>Please select</option>
      {solutions.length > 0 &&
      solutions.map((u) => (
      <option key={u._id} value={u._id}>
      {u.name}
      </option>
      ))}
      </select>
      </div>

      <CategoryForm
      handleSubmit={handleSubmit}
      name={name}
      setName={setName}
      />
      
      <LocalSearch keyword={keyword} setKeyword={setKeyword} />

      {/* step 5 */}
      {subsols.filter(searched(keyword)).map((b) =>(
        <div className="alert alert-secondary" key={b._id}>
        {b.name}
        <span  onClick={() => handleRemove(b.slug)} className="btn btn-sm float-right">
        <DeleteOutlined className="text-danger" />
        </span>
        <Link to={`/admin/sub-solution/${b.slug}`}>
        <span className="btn btn-sm float-right">
        <EditOutlined className="text-warning" />
        </span>
        </Link>
        </div>
      ))}
      </div>
    </div>
  </div>
  </div>
    );
};

export default SubsolCreate;