import React from "react";
import { Card } from "antd";
// import { Link } from "react-router-dom";
import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import defaul from "../../images/default-tico.png";
import FeatureListSols from "./FeatureListSols";
import DesignListSols from "./DesignListSols";
import ThreeModal from "../modals/ThreeModal";


const { Meta } = Card;

const SingleSol = ({ sol }) => {
  const { title, images, description } = sol;

  return (
    <>
      <div className="container">
        <div className="row">
          <div className="col-md-6 pr-5 pl-5 pb-4">
            {images && images.length ? (
              <Carousel showArrows={true} autoPlay infiniteLoop showStatus={false}>
                {images && images.map((i) => <img src={i.url} key={i.public_id} alt="Carousel of TICO Solutions"/>)}
              </Carousel>
            ) : (
              <Card
                cover={<img src={defaul} className="mb-3 card-image" alt="TICO default if uploaded is missing"/>}
              />
            )}
          </div>
          <div className="col-md-6 pl-0 pb-5">
            <h1 className="mt-5 pt-1 text-danger">{title}</h1>
            <Card bordered={false} bodyStyle={{padding: "0"}}>
              <Meta description={description}/>
              {/* <FeatureListSols sol={sol} /> */}
              {sol.sketchfab || sol.video ? (
                <ThreeModal title={title} sketchfab={sol.sketchfab} mp4File={sol.video} />
              ) : null}
            </Card>
          </div>
        </div>
      </div>

      <div className="container-fluid bgstill p-0">
        <div className="row">
          <div className="col-md-12 bgstill">
            <div className="container">
              <div className="row pt-5 pb-5">
                <div className="col-md-4 mb-5 offset-1">
                  <h3 className="text-danger">Key Features</h3>
                  <FeatureListSols sol={sol}/>
                </div>
                <div className="col-md-4 offset-1">
                  <h3 className="text-danger">Design Requirements</h3>
                  <DesignListSols sol={sol}/>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default SingleSol;
