import React, { useEffect, useState } from "react";
import AdminNav from "../../../components/nav/AdminNav";
import { getSolsByCount } from "../../../functions/sol";
import AdminSolCard from "../../../components/cards/AdminSolCard";
import { removeSol } from "../../../functions/sol";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";

const AllSols = () => {
  const [sols, setSols] = useState([]);
  const [loading, setLoading] = useState(false);
  const { user } = useSelector((state) => ({ ...state }));

  useEffect(() => {
    loadAllSols();
  }, []);

  const loadAllSols = () => {
    setLoading(true);
    getSolsByCount(50)
    .then((res) => {
      setSols(res.data);
      setLoading(false);
    })
    .catch((err) => {
      setLoading(false);
       console.log(err);
  });
  };

  const handleRemove = (slug) => {
    // let answer = window.confirm("Are you sure you want to delete?");
    if (window.confirm("Are you sure you want to delete?")) {
      // console.log("Send delete request", slug);
      removeSol(slug, user.token)
      .then(res => {
        loadAllSols();
        toast.error(`${res.data.title} has been deleted`);
      })
      .catch(err => {
        if (err.response.status === 400) toast.error(err.response.data);
        console.log(err);
      });
    }
  };

    return (
        <div className="container-fluid p-0 mt-5">
          <div className="row mx-0 angle-product mt-5 position-absolute">
  </div>
  <div className="container">
  <div className="row">
            <div className="col">
              <h1 className="text-white mb-3">Admin</h1>
            </div>
          </div>
    <div className="row mt-5">
      <div className="col-md-2">
        <AdminNav />
      </div>
      <div className="col">
      {loading ? (
      <h4 className="text-danger">Loading...</h4>
      ) : (
      <h4>All Solutions</h4>
      )}
      <div className="row">
      {sols.map((sol) => (
        <div key={sol._id} className="col-md-4 pb-3">
        <AdminSolCard sol={sol} handleRemove={handleRemove}
        />
        </div>
      ))}
      </div>
        </div>
    </div>
  </div>
  </div>
    );
};

export default AllSols;