import React, { useState, useEffect } from "react";
import AdminNav from "../../../components/nav/AdminNav";
import { toast } from "react-toastify";
import { useSelector } from "react-redux";
import { createSol } from "../../../functions/sol";
import SolCreateForm from "../../../components/forms/SolCreateForm";
import { getSolutions, getSolutionSubsols } from "../../../functions/solution";
import FileUpload from "../../../components/forms/FileUpload";
import { LoadingOutlined } from "@ant-design/icons";
import { getFormData } from "../utils";

const initialState = {
  title: "",
  description: "",
  solutions: [],
  solution: "",
  subsols: [],
  images: [],
  featurea: "",
  featureb: "",
  featurec: "",
  featured: "",
  designa: "",
  designb: "",
  designc: "",
  designd: "",
  sketchfab: "",
};

const SolCreate = () => {
  const [values, setValues] = useState(initialState);
  const [videoFile, setVideoFile] = useState(null);
  const [files, setFiles] = useState({brochure: null, datasheet: null})
  const [subsolOptions, setSubsolOptions] = useState([]);
  const [showSubsol, setShowSubsol] = useState(false);
  const [loading, setLoading] = useState(false);

  //redux
  const { user } = useSelector((state) => ({ ...state }));

  useEffect(() => {
    loadSolutions();
  }, []);

  const loadSolutions = () => {
    getSolutions().then((u) => setValues({...values, solutions: u.data}));
  }

  const handleSubmit = (e) => {
    e.preventDefault();
    const formData = getFormData(values);
    formData.append('video', videoFile);
    formData.append('brochure', files.brochure);
    formData.append('datasheet', files.datasheet);
    createSol(formData, user.token)
      .then((res) => {
        console.log(res);
        window.alert(`"${res.data.title}" is created`);
        window.location.reload();
      })
      .catch((err) => {
        console.log(err);
        toast.error(err.response.data.err);
      });
  };

  const handleVideoFileChange = (e) => {
    setVideoFile(e.target.files[0]);
  }

  const handleFileChange = (e) => {
    setFiles({...files, [e.target.name]: e.target.files[0]});
  }

  const handleChange = (e) => {
    setValues({...values, [e.target.name]: e.target.value});
  };

  const handleSolutionChange = (e) => {
    e.preventDefault()
    console.log('CLICKED SOLUTION', e.target.value);
    setValues({...values, subsols: [], solution: e.target.value});
    getSolutionSubsols(e.target.value).then(res => {
      console.log("SUBSOL OPTIONS ON SOLUTION CLICK", res);
      setSubsolOptions(res.data);
    });
    setShowSubsol(true);
  };

  return (
    <div className="container-fluid p-0 mt-5">
      <div className="row mx-0 angle-product mt-5 position-absolute">
      </div>
      <div className="container">
      <div className="row">
            <div className="col">
              <h1 className="text-danger mb-3">Admin</h1>
            </div>
          </div>
        <div className="row mt-5">
          <div className="col-md-2">
            <AdminNav/>
          </div>

          <div className="col-md-10">
            {loading ? (
              <LoadingOutlined className="text-danger h1"/>
            ) : (
              <h4>Create New Solution</h4>
            )}
            <hr/>
            <div className="p-3">
              <FileUpload values={values} setValues={setValues} setLoading={setLoading}/>
            </div>
            <SolCreateForm
              handleSubmit={handleSubmit}
              handleChange={handleChange}
              setValues={setValues}
              values={values}
              handleSolutionChange={handleSolutionChange}
              subsolOptions={subsolOptions}
              showSubsol={showSubsol}
              handleFileChange={handleFileChange}
              handleVideoFileChange={handleVideoFileChange}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default SolCreate;
