import { Modal } from "antd";
import React, { useState } from "react";
// import classNames from "classnames";
import { Link } from "react-router-dom";
import { DownloadOutlined } from "@ant-design/icons";

const FORM_ENDPOINT =
  "https://public.herotofu.com/v1/ac26c680-40d8-11ee-b711-0fdc810d0d65"; // TODO - fill on the later step

const BrochureModal = ({ product }) => {
  const [visible, setVisible] = useState(false);
  const { title } = product;
  const [submitted, setSubmitted] = useState(false);
  const handleSubmit = () => {
    setTimeout(() => {
      setSubmitted(true);
    }, 100);
  };

  if (submitted) {
    return (
      <>
        {product.brochure && (
          <div>
            <a href={product.brochure.url} target="_blank" rel="noreferrer">
              <h6 className="pt-5">VIEW BROCHURE</h6>
              <br />
              <DownloadOutlined className="" style={{ fontSize: "800%" }} />
              <p className="pt-5 pb-3 text-dark">
                Click to view product brochure. If you require further
                information, please contact us using the contact form, or by
                email or telephone - all can be found on the{" "}
                <Link to={`/contact-us`}>'Contact Us'</Link> web page.
              </p>
            </a>
          </div>
        )}
      </>
    );
  }

  return (
    <>
      <h6 className="pt-5">VIEW BROCHURE</h6>
      <DownloadOutlined
        className="text-danger pt-4"
        onClick={() => setVisible(true)}
        style={{ fontSize: "800%" }}
      />
      <p className="pt-5 pb-3 text-dark">
        Please click the above icon and enter your details to view this
        product's datasheet.
      </p>
      <Modal
        title="Enter your details to view product brochure"
        centered
        visible={visible}
        onOk={() => setVisible(false)}
        onCancel={() => setVisible(false)}
        width={600}
      >
        <div className="container">
          <div className="row mx-0 justify-content-center">
            <div className="col-md-12 col-lg-12 px-lg-2 col-xl-12 px-xl-0">
              <form
                action={FORM_ENDPOINT}
                onSubmit={handleSubmit}
                // className="w-full rounded p-4"
                className="w-full rounded p-4"
                method="POST"
                target="_blank"
              >
                <div className="mb-3 pt-0">
                  <input
                    type="text"
                    placeholder="Your name"
                    name="Name"
                    className="input-Box px-3 py-3 rounded text-sm border-0 outline-none focus:outline-none focus:ring w-100"
                    required
                  />
                </div>
                <div className="mb-3 pt-0">
                  <input
                    type="email"
                    placeholder="Email"
                    name="Email"
                    className="input-Box px-3 py-3 rounded text-sm border-0 outline-none focus:outline-none focus:ring w-100"
                    required
                  />
                </div>
                <div className="mb-3 pt-0">
                  <input
                    type="message"
                    value={title}
                    name="Brochure"
                    className="input-Box px-3 py-3 rounded text-sm border-0 outline-none focus:outline-none focus:ring w-100"
                  />
                </div>
                <div className="mb-3 pt-0">
                  <button
                    className="bg-danger text-white font-bold uppercase text-sm px-6 py-3 rounded mr-1 mb-1 ease-linear transition-all duration-150"
                    type="submit"
                  >
                    Submit your details
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </Modal>
    </>
  );
};

export default BrochureModal;
