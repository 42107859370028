import React from "react";
import { Image, Breadcrumb } from "antd";

const AboutUs = () => {
  return (
    <>
      <div className="container-fluid main p-0">
        <div className="row mx-0">
          <div className="col p-0 main stackParent">
            <img
              className="img-fluid head-img"
              width="100%"
              height="500px"
              src="https://res.cloudinary.com/dkfjd6cdn/image/upload/v1666713720/tico-solutions-tabs/Banner-about-tico-future-and-past_efysgn.jpg"
              alt="Discover about Tiflex's history and it's TICO vibration and noise isolation brand"
              preview={false}
            />
            <div className="stack-Txt">
              <div className="stack-Txt-child">About Us</div>
            </div>
          </div>
        </div>
      </div>

      <div className="container-fluid p-0 mt-n5">
        <div className="row">
          <div className="white-angle"></div>
        </div>
      </div>

      <div className="container mt-n5 p-0">
        <div className="row d-flex">
          <div className="col-md-12 d-flex flex-row-reverse">
            <Breadcrumb>
              <Breadcrumb.Item className="text-dark" href="/">
                <span>Home</span>
              </Breadcrumb.Item>
              <Breadcrumb.Item className="text-danger">
                About Us
              </Breadcrumb.Item>
            </Breadcrumb>
          </div>
        </div>
      </div>

      <div className="container p-0">
        <div className="row">
          <div className="col">
            <h1 className="mt-5 text-danger">
              History of Tiflex and the TICO Brand
            </h1>
            <p className="lead p-0 mt-2">
              Tiflex Limited was formed in 1990 from an amalgamation of two
              well-established companies, TICO &#40;founded in 1950&#41; and The
              Flexible Cork Company &#40;founded in 1945&#41;. Tiflex is part of
              the James Walker Group of Companies and was originally based in
              Hipley Street in Working, Surrey. In 2000, Tiflex moved entirely
              to the former Flexible Cork Company manufacturing site based at
              Liskeard in Cornwall, and employ over 140 skilled people on the
              107,639 sq ft, &#40;10,000 m<sup>2</sup>&#41; manufacturing site.{" "}
            </p>
          </div>
        </div>
      </div>

      <div className="container">
        <div className="row mb-5">
          <div className="col-md-6 col-sm-12">
            <Image
              className="img-fluid mt-3 p-0"
              src="https://res.cloudinary.com/dkfjd6cdn/image/upload/v1660039662/tico-solutions-tabs/services-bespoke-bearing-assembly_efa6y3.png"
              alt="bespoke bearing assembly"
            />
          </div>
          <div className="col-md-6 col-sm-12">
            <Image
              className="img-fluid mt-3"
              src="https://res.cloudinary.com/dkfjd6cdn/image/upload/v1660041844/tico-solutions-tabs/services-bespoke-laddish-bearings_vrmn3n.png"
              alt="Laddish bearings designed for large scale forging hammers"
            />
          </div>
        </div>
      </div>

      <div className="container pb-5">
        <div className="row mt-5">
          <div className="col p-0 mt-5">
            <h2 className="text-danger mt-5">Our Capabilites</h2>
            <p className="lead">
              We undertake high volume production as well as the manufacture of
              bespoke items, many of which are designed in-house on behalf of
              our customers. Work is carried out on major projects throughout
              the world and almost half of our production is sold overseas.
              Working closely with our customers, our polymer technologists work
              to develop solutions to a variety of engineering problems
              particularly those requiring high performance and product
              durability.
            </p>
            <p className="pt-1 mb-5 pb-4 lead">
              All Tiflex products are manufactured in a BSI registered factory
              operating a Quality Management System which complies with the
              requirements of BS EN ISO 9001:2015. We also have ISO 45001:2018
              &#40;Occupational Health &amp; Safety&#41; and ISO 14001:2015
              &#40;Environmental&#41; BSI certifications.
            </p>
          </div>
        </div>
      </div>

      <div className="row mt-5 mb-5 bgstill">
        <div className="container">
          <div className="col-12 pt-5 pb-5">
            <h2 className="text-danger text-center mt-5 pt-5 pl-5">
              Laboratory Test Facilities
            </h2>
            <p className="lead text-center pl-5 pr-5">
              Tiflex has a state of the art technological laboratory, providing
              us with the facilities to undertake our own internal testing. Our
              products are sold into a diverse range of industries and regions
              of the world and we are required to meet many different industry
              and country standards.
            </p>
            <p className="lead text-center pt-3 pr-5 pl-5 pb-5 mb-5">
              Tiflex products are manufactured and tested to ensure that they
              can withstand even the most strenuous of conditions. Part of the laboratory is dedicated to
              evaluating static, dynamic and impact performance, in addition to
              the general testing regimes to evaluate materials and processes in
              line with our company quality systems.
            </p>
          </div>
        </div>
      </div>

      <div className="container p-0">
        <div className="row pt-5">
          <div className="col">
            <h2 className="text-danger pt-5 ">Technical Support</h2>
            <p className="pt-1 lead">
              All of our products are supported by full commercial and technical
              literature and supported by a technical team to provide you with
              pre-contract and on-site assistance. Our experienced team will
              work with you to ensure the correct selection of product and will,
              where necessary, provide advice on design.
            </p>
          </div>
        </div>
        <div className="row">
          <div className="col">
            <Image
              className="img-fluid pt-3 pb-5"
              src="https://res.cloudinary.com/dkfjd6cdn/image/upload/v1675159145/tico-solutions-tabs/Tico-technical-support_ypdbun.png"
              alt="Our technical support are here to help with every step of a project installation using our products"
            />
          </div>
        </div>
        <div className="col-12 mt-4 pt-5 pb-4">
          <h2 className="text-danger pt-5">Research &amp; Development</h2>
          <p className="lead pb-5">
            Tiflex are committed to a programme of continuous improvement, not
            only for our staff, but also for materials and the production
            processes involved in the manufacture of these products. Research
            and development is a fundamental part of the companies forward
            thinking plan and strategy, whilst being committed to developing new
            and innovative products for the future of industry.
          </p>
        </div>

        <div className="container">
        <div className="row mb-5">
          <div className="col-12 text-center">
        <h2 className="text-danger pt-5">Other Tiflex Brands</h2>
        </div>
          <div className="col-md-4 col-sm-6 text-center">
          <a href="https://www.treadmasterflooring.com/" rel="noreferrer" target="_blank">
            <Image
              className="img-fluid mt-3 p-0"
              src="https://res.cloudinary.com/dkfjd6cdn/image/upload/v1676989546/tico-solutions-tabs/about-treadmaster-flooring-logo_t3j2ki.png"
              alt="Treadmaster Flooring logo"
              preview={false}
            />
            <p>Treadmaster Flooring - Since 1951 Tiflex have been supplying Treadmaster Flooring to the transport industry.</p>
            </a>
          </div>
          <div className="col-md-4 col-sm-6 text-center">
          <a href="https://www.trackelast.com/" rel="noreferrer" target="_blank">
            <Image
              className="img-fluid mt-3"
              src="https://res.cloudinary.com/dkfjd6cdn/image/upload/v1676989546/tico-solutions-tabs/about-trackelast-logo_pyk9bx.png"
              alt="Trackelast logo"
              preview={false}
            />
            <p>Since 1946 Trackelast has specialised in the design, development, testing and manufacture of resilient rail support materials.</p>
            </a>
          </div>
          <div className="col-md-4 col-sm-6 text-center">
          <a href="https://www.treadmaster.co.uk/" rel="noreferrer" target="_blank">
            <Image
              className="img-fluid mt-3"
              src="https://res.cloudinary.com/dkfjd6cdn/image/upload/v1676989546/tico-solutions-tabs/about-treadmaster-marine-logo_huvrdq.png"
              alt="Treadmaster Marine logo"
              preview={false}
            />
            <p>TREADMASTER Marine Anti-slip decking, teak effect decking, self-adhesive grip pads, alongside marine adhesives and Treadmaster cleaner.</p>
            </a>
          </div>
        </div>
        <div className="row justify-content-center mb-5">
          <div className="col-md-4 col-sm-6 text-center">
          <a href="https://www.oxbridgeballs.co.uk/" rel="noreferrer" target="_blank">
            <Image
              className="img-fluid mt-3 p-0"
              src="https://res.cloudinary.com/dkfjd6cdn/image/upload/v1676989546/tico-solutions-tabs/about-oxbridge-cricket-balls-logo_gjavmh.png"
              alt="Oxbridge Cricket Balls logo"
              preview={false}
            />
            <p>Oxbridge Cricket balls, a comprehensive range of cricket balls including multi coloured and the new pink cricket balls.</p>
            </a>
          </div>
          <div className="col-md-4 col-sm-6 text-center">
          <a href="https://www.tiflex.co.uk/nebar/nebar.html" rel="noreferrer" target="_blank">
            <Image
              className="img-fluid mt-3"
              src="https://res.cloudinary.com/dkfjd6cdn/image/upload/v1676989546/tico-solutions-tabs/about-nebar-gasket-material_lcnn24.png"
              alt="Nebar logo"
              preview={false}
            />
            <p>Tiflex Limited is the sole manufacturer of Nebar, rubber/cork gasket materials.</p>
            </a>
          </div>
        </div>
      </div>
      </div>
    </>
  );
};

export default AboutUs;
