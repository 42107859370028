import { Card, Button, Breadcrumb } from "antd";
import React from "react";
import { Link } from "react-router-dom";

// const contentStyle: React.CSSProperties = {
//     height: '160px',
//     color: '#fff',
//     lineHeight: '160px',
//     textAlign: 'center',
//     background: '#364d79',
//   };

const imgStyle: React.CSSProperties = {
  width: "100%",
  height: "auto",
  lineHeight: "160px",
  textAlign: "center",
};

const { Meta } = Card;

const Construction = () => {
  return (
    <>
      <div className="container-fluid main p-0">
        <div className="row mx-0">
          <div className="col p-0 main stackParent">
            <img
              className="img-fluid head-img"
              width="100%"
              height="500px"
              src="https://res.cloudinary.com/dkfjd6cdn/image/upload/v1669117120/tico-solutions-tabs/industrial-construction-header_likzly.jpg"
              alt="TICO and the many industries we supply to for noise and vibration isolations"
              preview={false}
            />
            <div className="stack-Txt">
              <div className="stack-Txt-child">Construction</div>
            </div>
          </div>
        </div>
      </div>

      <div className="container-fluid p-0 mt-n5">
        <div className="row">
          <div className="white-angle"></div>
        </div>
      </div>

      <div className="container mt-n5 p-0">
        <div className="row d-flex">
          <div className="col-md-12 d-flex flex-row-reverse">
            <Breadcrumb>
              <Breadcrumb.Item className="text-dark" href="/">
                <span>Home</span>
              </Breadcrumb.Item>
              <Breadcrumb.Item className="text-dark" href="/industries">
                <span>Industries</span>
              </Breadcrumb.Item>
              <Breadcrumb.Item className="text-danger">
                Construction
              </Breadcrumb.Item>
            </Breadcrumb>
          </div>
        </div>
      </div>

      <div className="container p-0">
        <div className="row">
          <div className="col-12">
            <h1 className="mt-5 text-danger">
              Products for the Construction Industry
            </h1>
            <p className="lead p-0 mt-2 mb-5">
              TICO produces a range of products that are used throughout the
              Building and Construction industry to control and isolate
              vibration both internally and externally from road, rail and heavy
              industry. The TICO range of products is incorporated within new
              and existing building projects and includes Impact Attenuation
              Pads, Structural &amp; Slide bearings and Resilient Seatings.
            </p>
          </div>
        </div>
      </div>

      <div className="container">
        <div className="row justify-content-md-center mb-5 pb-5">
          <div className="col-12 mt-5">
            <h3 className="text-center">Typical Applications</h3>
          </div>
          <div className="col-6 col-md-3 pb-3">
            <div>
              <img
                style={imgStyle}
                src="https://res.cloudinary.com/dkfjd6cdn/image/upload/v1659618653/tico-solutions-tabs/industrial-for-construction_vuhafe.png"
                alt="Tico products suitable for the construction industry - industrial"
              />
            </div>
          </div>
          <div className="col-6 col-md-3 pb-3">
            <div>
              <img
                style={imgStyle}
                src="https://res.cloudinary.com/dkfjd6cdn/image/upload/v1659618655/tico-solutions-tabs/construction-for-construction_qvddoc.png"
                alt="Tico products suitable for the construction industry - construction"
              />
            </div>
          </div>
          <div className="col-6 col-md-3 pb-3">
            <div>
              <img
                style={imgStyle}
                src="https://res.cloudinary.com/dkfjd6cdn/image/upload/v1659618653/tico-solutions-tabs/production-for-construction_bylwgx.png"
                alt="Tico products suitable for the construction industry - production"
              />
            </div>
          </div>
          <div className="w-100 d-none d-md-block"></div>
          <div className="col-6 col-md-3 pb-3">
            <div>
              <img
                style={imgStyle}
                src="https://res.cloudinary.com/dkfjd6cdn/image/upload/v1659618655/tico-solutions-tabs/metrology-for-construction_odq9aj.png"
                alt="Tico products suitable for the construction industry - metrology"
              />
            </div>
          </div>
          <div className="col-6 col-md-3 pb-3">
            <div>
              <img
                style={imgStyle}
                src="https://res.cloudinary.com/dkfjd6cdn/image/upload/v1659618653/tico-solutions-tabs/fabrication-for-construction_iszee9.png"
                alt="Tico products suitable for the construction industry - fabrication"
              />
            </div>
          </div>
          <div className="col-6 col-md-3 pb-3">
            <div>
              <img
                style={imgStyle}
                src="https://res.cloudinary.com/dkfjd6cdn/image/upload/v1659618655/tico-solutions-tabs/forging-for-construction_al3gsg.png"
                alt="Tico products suitable for the construction industry - forging"
              />
            </div>
          </div>
        </div>
      </div>

      <div className="row mt-5 mb-5 bgstill">
        <div className="container">
          <div className="col-12 mt-5 pt-3 pb-4">
            <p className="lead text-center p-5 mt-5 mb-5">
              Working with architects and design houses, we are able to assist
              with the concept and manufacturing of bespoke products around new
              and existing architecture. Our products are backed with full
              support from our Technical Support Team who is able to advise on
              the best solutions to any vibration problem.
            </p>
          </div>
        </div>
      </div>

      <div className="container">
        {/* row 1 of products */}
        <div className="row">
          <div className="col-12 mt-5">
            <h3>Suitable Products</h3>
            <p className="lead">
              We have developed a range of anti-vibration, high temperature and
              fire retardant Pipe Support products for use within the Oil &amp;
              Gas industry including Pipe Supports, Clamp Lining Clip Strips and
              Sliding Bearings.
            </p>
          </div>
          <div className="col-md-4 mb-4">
            <Card
              cover={
                <img
                  alt="bearing assemblies"
                  src="https://res.cloudinary.com/dkfjd6cdn/image/upload/v1662454158/wdn9mypaxfstinojsc2n.jpg"
                />
              }
              actions={[
                <Link to={`/sol/bearing-assemblies`}>
                  <Button type="primary" className="float-left ml-4">
                    View Product
                  </Button>
                </Link>,
              ]}
            >
              <Link to={`/sol/bearing-assemblies`}>
                <Meta
                  title="Bearing Assemblies"
                  description="Designed to protect a building from ground-borne noise such as metro and underground systems, road networks etc...."
                />
              </Link>
            </Card>
          </div>

          <div className="col-md-4 mb-4">
            <Card
              cover={
                <img
                  alt="Bearing structural pads"
                  src="https://res.cloudinary.com/dkfjd6cdn/image/upload/v1660211252/l7wypihalvagkgbrqnch.jpg"
                />
              }
              actions={[
                <Link to={`/sol/bearing-pads`}>
                  <Button type="primary" className="float-left ml-4">
                    View Product
                  </Button>
                </Link>,
              ]}
            >
              <Link to={`/sol/bearing-pads`}>
                <Meta
                  title="Bearing Pads"
                  description="Used for structural isolation of buildings and structures. They are designed to protect a building from ground-borne noise..."
                />
              </Link>
            </Card>
          </div>

          <div className="col-md-4 mb-4">
            <Card
              cover={
                <img
                  alt="Tico slide or movement bearings"
                  src="https://res.cloudinary.com/dkfjd6cdn/image/upload/v1664532715/ppmxaaluq9csy2fcp3ly.jpg"
                />
              }
              actions={[
                <Link to={`/sol/slide-bearing-assemblies`}>
                  <Button type="primary" className="float-left ml-4">
                    View Product
                  </Button>
                </Link>,
              ]}
            >
              <Link to={`/sol/slide-bearing-assemblies`}>
                <Meta
                  title="Slide Bearings"
                  description="Designed to accommodate large allowable movements within a wide range of applications..."
                />
              </Link>
            </Card>
          </div>
        </div>

        {/* row 2 of products */}

        <div className="row">
          <div className="col-md-4 mb-4">
            <Card
              cover={
                <img
                  alt="resilient seatings"
                  src="https://res.cloudinary.com/dkfjd6cdn/image/upload/v1661877151/i8ylmw0v6j8vjdplc6es.jpg"
                />
              }
              actions={[
                <Link to={`/sol/resilient-seatings`}>
                  <Button type="primary" className="float-left ml-4">
                    View Product
                  </Button>
                </Link>,
              ]}
            >
              <Link to={`/sol/resilient-seatings`}>
                <Meta
                  title="Resilient Seatings"
                  description="2 main functions: 1) to introduce a resilient discontinuity within a structure for the control of vibration..."
                />
              </Link>
            </Card>
          </div>

          <div className="col-md-4 mb-4">
            <Card
              cover={
                <img
                  alt="Thermal break pads"
                  src="https://res.cloudinary.com/dkfjd6cdn/image/upload/v1663844743/bnlsl4meu1hyd8znm8m2.jpg"
                />
              }
              actions={[
                <Link to={`/sol/thermal-break-pads`}>
                  <Button type="primary" className="float-left ml-4">
                    View Product
                  </Button>
                </Link>,
              ]}
            >
              <Link to={`/sol/thermal-break-pads`}>
                <Meta
                  title="Thermal Break Pads"
                  description="Structural Thermal Break plates are high performance thermal insulators used between both horizontal and vertical..."
                />
              </Link>
            </Card>
          </div>

          <div className="col-md-4 mb-4">
            <Card
              cover={
                <img
                  alt="access floor"
                  src="https://res.cloudinary.com/dkfjd6cdn/image/upload/v1664785168/mzbzdfyeycrugkwpamom.jpg"
                />
              }
              actions={[
                <Link to={`/sol/access-floor-isolation`}>
                  <Button type="primary" className="float-left ml-4">
                    View Product
                  </Button>
                </Link>,
              ]}
            >
              <Link to={`/sol/access-floor-isolation`}>
                <Meta
                  title="Access floor"
                  description="Typically consisting of a pedestal and cradle design, TICO pads are an important component to stop the transfer..."
                />
              </Link>
            </Card>
          </div>
        </div>

        {/* row 3 of products */}

        <div className="row">
          <div className="col-md-4 mb-4">
            <Card
              cover={
                <img
                  alt="concrete floor isolation"
                  src="https://res.cloudinary.com/dkfjd6cdn/image/upload/v1663855658/f3e3hagot4u9leqmbobw.jpg"
                />
              }
              actions={[
                <Link to={`/sol/concrete-floating-floor-isolation`}>
                  <Button type="primary" className="float-left ml-4">
                    View Product
                  </Button>
                </Link>,
              ]}
            >
              <Link to={`/sol/concrete-floating-floor-isolation`}>
                <Meta
                  title="Wet Floors"
                  description="Designed to create low frequency isolating floating floors as used in gyms, music studios etc..."
                />
              </Link>
            </Card>
          </div>

          <div className="col-md-4 mb-4">
            <Card
              cover={
                <img
                  alt="dry floors"
                  src="https://res.cloudinary.com/dkfjd6cdn/image/upload/v1663855532/jnldkiocma8jhasimv62.jpg"
                />
              }
              actions={[
                <Link to={`/sol/lightweight-floor-isolation`}>
                  <Button type="primary" className="float-left ml-4">
                    View Product
                  </Button>
                </Link>,
              ]}
            >
              <Link to={`/sol/lightweight-floor-isolation`}>
                <Meta
                  title="Dry Floor"
                  description="Floor isolation products are designed to create low frequency isolating floating floors as used in gyms..."
                />
              </Link>
            </Card>
          </div>

          <div className="col-md-4 mb-4">
            <Card
              cover={
                <img
                  alt="perimeter strip"
                  src="https://res.cloudinary.com/dkfjd6cdn/image/upload/v1663854152/pt4i0ltdswcipezoawuz.jpg"
                />
              }
              actions={[
                <Link to={`/sol/perimeter-strips`}>
                  <Button type="primary" className="float-left ml-4">
                    View Product
                  </Button>
                </Link>,
              ]}
            >
              <Link to={`/sol/perimeter-strips`}>
                <Meta
                  title="Perimeter Strip"
                  description="Commonly used to minimise noise transfer from an isolated to a non-isolated building component such as a floating floor..."
                />
              </Link>
            </Card>
          </div>
        </div>

        {/* row 4 of products */}

        <div className="row">
          <div className="col-md-4 mb-4">
            <Card
              cover={
                <img
                  alt="wall base isolation"
                  src="https://res.cloudinary.com/dkfjd6cdn/image/upload/v1663853314/ofc1b5qnczljmzyjx6nt.jpg"
                />
              }
              actions={[
                <Link to={`/sol/wall-base-isolation`}>
                  <Button type="primary" className="float-left ml-4">
                    View Product
                  </Button>
                </Link>,
              ]}
            >
              <Link to={`/sol/wall-base-isolation`}>
                <Meta
                  title="Wall Base Isolation"
                  description="TICO resilient materials are used to create a resilient barrier to providing a separation for use under partition..."
                />
              </Link>
            </Card>
          </div>

          <div className="col-md-4 mb-4">
            <Card
              cover={
                <img
                  alt="staircase vibrtion isolation"
                  src="https://res.cloudinary.com/dkfjd6cdn/image/upload/v1663853379/ve3kg3zwkfqksgr5oldw.jpg"
                />
              }
              actions={[
                <Link to={`/sol/staircase-isolation`}>
                  <Button type="primary" className="float-left ml-4">
                    View Product
                  </Button>
                </Link>,
              ]}
            >
              <Link to={`/sol/staircase-isolation`}>
                <Meta
                  title="Staircase Isolation"
                  description="Stairwells are the most common source of impact noise within a structure. This noise left un-isolated can find its way..."
                />
              </Link>
            </Card>
          </div>

          <div className="col-md-4 mb-4">
            <Card
              cover={
                <img
                  alt="cross-laminated timber vibration isolation"
                  src="https://res.cloudinary.com/dkfjd6cdn/image/upload/v1663853957/oiowpmqwq1kdu4zl3kkv.jpg"
                />
              }
              actions={[
                <Link to={`/sol/cross-laminated-timber`}>
                  <Button type="primary" className="float-left ml-4">
                    View Product
                  </Button>
                </Link>,
              ]}
            >
              <Link to={`/sol/cross-laminated-timber`}>
                <Meta
                  title="Cross-laminated Timber"
                  description="Timber structures are increasingly common as they have many benefits over traditional steel and concrete structures..."
                />
              </Link>
            </Card>
          </div>
        </div>
      </div>
    </>
  );
};

export default Construction;
