import axios from 'axios';

export const getSubsols = async () =>
  await axios.get(`${process.env.REACT_APP_API}/sub-solutions`);

  export const getSubsol = async (slug) =>
  await axios.get(`${process.env.REACT_APP_API}/sub-solution/${slug}`);

  export const removeSubsol = async (slug, authtoken) =>
  await axios.delete(`${process.env.REACT_APP_API}/sub-solution/${slug}`, {
      headers: {
          authtoken,
      },
  });

    export const updateSubsol = async (slug, subsol, authtoken) =>
  await axios.put(`${process.env.REACT_APP_API}/sub-solution/${slug}`, subsol, {
    headers: {
      authtoken,
    },
  });

     export const createSubsol = async (subsol, authtoken) =>
  await axios.post(`${process.env.REACT_APP_API}/sub-solution`, subsol, {
      headers: {
          authtoken,
      },
  });