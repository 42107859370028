import React from "react";
// import { Link } from "react-router-dom";

const FeatureListSols = ({sol}) => {
  const {featurea, featureb, featurec, featured} = sol;
  return (
    <ul className="list-group list-group-mine">
      {featurea && (
        <li className="list-group-item borderless bullet-point-container">
          <span className="text-danger">+</span>
          <span className="label label-default label-pill pull-xs-right small">
          {featurea}
        </span>
        </li>
      )}

      {featureb && (
        <li className="list-group-item borderless bullet-point-container">
          <span className="text-danger">+</span>
          <span className="label label-default label-pill pull-xs-right small">
          {featureb}
        </span>
        </li>
      )}

      {featurec && (
        <li className="list-group-item borderless bullet-point-container">
          <span className="text-danger">+</span>
          <span className="label label-default label-pill pull-xs-right small">
          {featurec}
        </span>
        </li>
      )}

      {featured && (
        <li className="list-group-item borderless bullet-point-container">
          <span className="text-danger">+</span>
          <span className="label label-default label-pill pull-xs-right small">
          {featured}
        </span>
        </li>
      )}
    </ul>
  );
};

export default FeatureListSols;
